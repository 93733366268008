<template>
  <div>
    <v-snackbar
      v-if="alert.active"
      v-model="alert.active"
      :color="alert.type"
      :dismissible="alert.type == 'error'"
      class="rounded-b-0 mb-0">
      <div
        class="fill-width text-center font-weight-medium"
        >
        {{ alert.message }}
      </div>
    </v-snackbar>
    <v-card
      outlined
      class="rounded-lg"
      >
      <v-card-title
        class="justify-center"
        >
        Banners de {{ $t('versions.' + version) }}
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-row>
            <v-col
              cols="12"
              >
              <v-file-input
                label="Cargar nuevos banners..."
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                small-chips
                filled
                v-model="banners"
                :hint="version == 'desktop' ? 'Las dimensiones recomendadas son 958px de ancho x 300px de alto.' : 'El alto máximo no debe superar los 400px.'"
                accept="image/*"
                multiple
                persistent-hint
                truncate-length="15"
                ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              >
              <div
                class="text-subtitle-1 text--primary text-center"
                >
                Vista previa
              </div>
            </v-col>

            <v-col
              v-if="header && (header[attributable[version]] || []).length"
              cols="12"
              class="mx-auto px-0"
              :style="version == 'desktop' ? 'max-width: 958px' : 'max-width: 300px'"
              >
              <v-carousel
                class="rounded-lg fill-width"
                hide-delimiters
                cycle
                :show-arrows="!$vuetify.breakpoint.mobile && (header[attributable[version]].length > 1)"
                style="max-height: 300px"
                height="auto"
                >
                <v-carousel-item
                  v-for="banner in header[attributable[version]]"
                  v-if="!banner._destroy"
                  :src="url(banner)"
                  style="max-height: 300px"
                  >
                  <div
                    style="position: absolute; top: 12px; right: 12px; gap: 12px"
                    class="d-flex"
                    >
                    <v-tooltip 
                      v-if="!banner.id"
                      color="info"
                      bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          color="info"
                          size="35"
                          v-bind="attrs"
                          v-on="on"
                          >
                          <v-icon
                            size="20"
                            color="white"
                            >
                            mdi-content-save-alert
                          </v-icon>
                        </v-avatar>
                      </template>

                      <span>
                        Banner sin guardar.
                      </span>
                    </v-tooltip>

                    <v-btn
                      color="error"
                      fab
                      x-small
                      @click="removeBanner(banner)"
                      >
                      <v-icon>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-form>

        <div
          class="mt-6 text-right"
          >
          <v-btn
            class="rounded-lg"
            color="primary"
            @click="save"
            >
            Guardar cambios
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Categories } from '@/graphql/queries/categories'
import { Edit } from '@/graphql/mutations/header'

export default {
  data () {
    return {
      valid: true,
      preview: false,
      header: {},
      banners: [],
      categories: [],
      attributable: {
        desktop: 'bannersAttributes',
        mobile: 'mobileBannersAttributes'
      },
      fetchable: {
        desktop: 'banners',
        mobile: 'mobileBanners'
      },
      productsType: [
        {
          text: 'Destacados',
          value: 'featured',
        },
        {
          text: 'Recientes',
          value: 'recent'
        }
      ],
      alert: {
        active: false,
        type: 'error',
        message: ''
      },
      rules: {
        required: [ v => !!v || 'Campo obligatorio' ]
      }
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  created () {
    this.header = JSON.parse(JSON.stringify(this.user.store.header))
    this.header.height = "300"
    this.header[this.attributable[this.version]] = this.header[this.fetchable[this.version]]
    delete this.header.banners
    delete this.header.mobileBanners
    delete this.header.products
    delete this.header.__typename
    this.fetchCategories()
  },

  watch: {
    banners (val) {
      val.forEach( banner => {
        var image = banner
        var data = {
          storeId: this.user.store.id,
          body: null,
          title: null,
          color: null,
          imageUrl: URL.createObjectURL(banner),
          linkableType: 'Category',
          linkableId: null,
          bannerType: this.version,
          imageAttributes: {
            image: banner,
            name: banner.name,
            size: banner.size,
          }
        }

        this.header[this.attributable[this.version]].push(data)
      })
    }
  },

  props: {
    user: {
      type: Object,
      required: true
    },

    version: {
      type: String,
      required: true
    },
  },

  methods: {
    save () {
      var header = { ...this.header }
      var aux = []

      header[this.attributable[this.version]].forEach( (banner, idx) => {
        aux[idx] = banner.imageUrl
        delete banner.imageUrl
        delete banner.__typename
      })

      if (this.$refs.form.validate()) {
        this.$apollo.mutate({
          mutation: Edit,
          variables: {
            input: {
              attributes: header
            }
          },
        }).then( res => {
          this.header[this.attributable[this.version]].forEach( (banner, idx) => {
            banner.imageUrl = aux[idx]
          })
          this.alert.message = this.$t('object.edit.success')
          this.alert.type = 'success'
          this.alert.active = true
          setTimeout( () => {
            this.alert.active = false
          }, 2000)
        }).catch( err => {
          this.alert.message = this.$t('validation.' + err.graphQLErrors[0].extensions.message, { field: err.graphQLErrors[0].extensions.field})
          this.alert.type = 'error'
          this.alert.active = true
        })
      }
    },

    url (banner) {
      var url = banner.id ? (process.env.VUE_APP_RAILS_URL + banner.imageUrl) : banner.imageUrl
      return url
    },

    fetchCategories () {
      this.$apollo.query({
        query: Categories,
      }).then( res => {
        this.categories = res.data.categories
      })
    },

    removeBanner (banner) {
      if (banner.id) {
        banner._destroy = true
      } else {
        var index = this.header[this.attributable[this.version]].indexOf(banner)
        this.header[this.attributable[this.version]].splice(index, 1)
      }
      this.$forceUpdate()
    }
  }
}
</script>
